import React, { Component } from 'react';
import { Link, graphql } from "gatsby";
import AOS from 'aos';

import PrimaryLayout from "../layouts/PrimaryLayout"
import SEO from '../layouts/SEO';

import PlayIcon from '../assets/icons/play-button.svg';

class VideoBlogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: 4,
      items: 0,
      loadingState: false
    };

    this.loadMore = this.loadMore.bind(this);
    this.myRef = React.createRef();
  }

  loadMore() {    
    this.setState({ loadingState: true });
    setTimeout(() => {
      this.setState((prev) => {
        return {visible: prev.visible + 15, loadingState: false};
      });
      if (typeof window !== 'undefined' && this.state.visible < this.state.items ) {
        const height = document.getElementsByTagName('article')[0].clientHeight;
        window.scrollTo({behavior: 'smooth', top: this.myRef.current.offsetTop - (height * 16)})
      }
    }, 100);
  }

  componentDidMount(){
    this.setState({
      items : this.props.data.allWordpressPost.nodes.length
    });
    if (typeof window !== 'undefined') {
      AOS.init({ duration : 800});
    }
    

    // this.refs.iScroll.addEventListener("scroll", () => {
    //   if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >=this.refs.iScroll.scrollHeight){
    //     this.loadMore();
    //   }
    // });
  }

  render() {
    const post = this.props.data.allWordpressPost.nodes;
    const page = this.props.data.wordpressPage;
    // console.log(post); 
    return (
      <PrimaryLayout>
        <SEO 
          title={page.acf.seo_title}
          description={page.acf.seo_description}
          keywords={page.acf.seo_keywords}
        />
        <section id="primary" className="content-area">
          <main id="main" className="site-main">
            <div className="container-fluid">
              <div className="row p-0 m-0">
                <div className="container main-container-padding-t-5 main-container-padding-b-2">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="header-text-container">
                        <div className="vertical-center">
                          <h1 data-aos="fade-up">
                            All
                            <br />
                            Video's.
                          </h1>
                          <h3 data-aos="fade-up">
                            Keep up-to-date with innovation &amp; disruption.
                          </h3>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img
                        data-src="https://storage.googleapis.com/alkye.com/1/2020/06/blog-1.jpg"
                        className="header-image lazyload"
                        alt="banner"
                         data-aos="fade-up"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div className="blogposts bg-white py-5 ">
                <div className="container">
                  <div className="row">
                      {post.map((node, index) => (
                        <div key={index} className="col-sm-12 col-lg-4 mb-4">
                          <Link to={'/' + node.slug} className="video-post d-block" style={{ backgroundImage: `url(${node.featured_media && node.featured_media.source_url})` }}>
                            <span className="video-play"><img src={PlayIcon} alt="play" /></span>
                            <span className="video-caption">
                              <span className="video-caption-less" dangerouslySetInnerHTML={{__html: node.title.length > 65 ? node.title.substring(0, 65) + "..." : node.title }} />
                              <span className="video-caption-more" dangerouslySetInnerHTML={{__html: node.title }} />
                            </span>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Load More */}
              {/* {this.state.visible < this.state.items &&
              <div className="load_more text-center py-5 bg-white">
                <button onClick={this.loadMore} ref={this.myRef} className="btn btn-loadmore" type="button">{this.state.loadingState ? 'loading More Items..' : 'Load More'}</button>
              </div>} */}
          </main>
        </section>
      </PrimaryLayout>
    );
  }
}

export default VideoBlogs;


export const query = graphql`
{
  wordpressPage(wordpress_id: {eq: 94}) {
    acf {
      seo_title
      seo_keywords
      seo_description
    }
  }
  allWordpressPost {
    nodes {
      title
      slug
      featured_media {
        source_url
      }
    }
  }
}
`